export enum VehicleWeightLimitFilterMode {
    All,
    Above35Ton
}

export enum VehicleParametersExceedeFilterMode {
    All,
    OnlyWithExceededParameter
}

export type PortalSettingsStoreType = {
    weightLimitFilterMode: VehicleWeightLimitFilterMode;
    parametersExceededFilterMode: VehicleParametersExceedeFilterMode;
}