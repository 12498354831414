"use client"
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { PlatformStatusDetailsType } from '@/types';

export const platformStatusesSlice = createSlice({
  name: 'platformStatuses',
  initialState,
  reducers: {
    setPlatformStatusesData: (state, action: PayloadAction<PlatformStatusDetailsType[]>) => {
      const elements = state.data;
      action.payload.forEach((item) => {
        let existingElement = elements.find((x) => x.metricPointPlatformUId === item.metricPointPlatformUId);
        if (existingElement != undefined) {
          existingElement = item;
        } else {
          elements.push(item);
        }
      });
      state.data = elements;
    },
    clearData: (state) => {
      state.data = []
    }
  },
});

export const { 
  setPlatformStatusesData,
  clearData
} = platformStatusesSlice.actions;
export default platformStatusesSlice.reducer;
