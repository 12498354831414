import dynamic from 'next/dynamic';

const PlatformStatuses = dynamic(
  () => import('@/features/PlatformStatuses/PlatformStatuses'), { ssr: false }
);

const LiveCameraPreview = dynamic(
  () => import('@/features/LiveCameraPreview/LiveCameraPreview'), { ssr: false }
);
const Reports = dynamic(() => import('@/features/Reports/Reports'), { ssr: false });
const LiveVehiclesRecords = dynamic(
  () => import('@/features/LiveVehiclesRecords/LiveVehiclesRecords'), { ssr: false }
);
const VehicleList = dynamic(() => import('@/features/VehicleList/VehicleList'), { ssr: false });
const LocalizationHeaderDropdown = dynamic(() => import('@/features/LocalizationHeaderDropdown/LocalizationHeaderDropdown'), { ssr: false });
const PortalSettingsHeaderDropdown = dynamic(() => import('@/features/PortalSettingsHeaderDropdown/PortalSettingsHeaderDropdown'), { ssr: false});

export {
  PlatformStatuses,
  LiveCameraPreview,
  Reports,
  LiveVehiclesRecords,
  VehicleList,
  LocalizationHeaderDropdown,
  PortalSettingsHeaderDropdown
};
