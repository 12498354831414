"use client"
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { VehicleRecordDetailsType } from '@/types';

export const liveViewSlice = createSlice({
  name: 'liveView',
  initialState,
  reducers: {
    setAutoUpdateVehicleDetails: (state, action: PayloadAction<boolean>) => {
      state.autoUpdateVehicleDetails = action.payload;
    },
    setMetricPointPlatformsUIds: (state, action: PayloadAction<string[]>) => {
      state.metricPointPlatformsUIds = action.payload;
    },
    setActiveRecord: (state, action: PayloadAction<VehicleRecordDetailsType | undefined>) => {
      state.activeRecord = action.payload;
    },
    setVehiclesList: (state, action: PayloadAction<VehicleRecordDetailsType[]>) => {
      state.vehiclesList = action.payload;
    },
    addToVehiclesList: (state, action: PayloadAction<VehicleRecordDetailsType[]>) => {
      action.payload.reverse().forEach((item) => {
        const existingelement = state.vehiclesList.find((x) => x.id === item.id);
        if (existingelement == null) {
          state.vehiclesList.unshift(item);
        }
      });
      if (state.autoUpdateVehicleDetails) {
        state.activeRecord = state.vehiclesList[0];
      }
    }
  },
});

export const { 
  setAutoUpdateVehicleDetails,
  setMetricPointPlatformsUIds,
  setActiveRecord,
  setVehiclesList,
  addToVehiclesList
} = liveViewSlice.actions;
export default liveViewSlice.reducer;
