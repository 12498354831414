'use client'
import React, { useEffect } from 'react'
import * as Features from '../features'
import { PageRenderer, useWhitelabeling } from '@develos/next-library'
import { Providers } from '@/app/providers/storeProvider'

const AppRenderer = () => {
  const { applyWhitelabeling } = useWhitelabeling()

  useEffect(() => {
    applyWhitelabeling()
  }, [])
  
  return (
    <Providers>
      <PageRenderer layouts={{}} features={Features} skins={{}} />
    </Providers>
  )

}

export default AppRenderer
