"use client"
import { combineReducers } from '@reduxjs/toolkit';

import metricLocalizationReducer from './metricLocalization/metricLocalizationSlice';
import liveViewReducer from './liveView/liveViewSlice';
import platformStatusesReducer from './platformStatuses/slice';
import portalSettingsReducer from './portalSettings/slice';

const rootReducer = combineReducers({
  metricLocalization: metricLocalizationReducer,
  liveView: liveViewReducer,
  platformStatuses: platformStatusesReducer,
  portalSettings: portalSettingsReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
