"use client"
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { VehicleParametersExceedeFilterMode, VehicleWeightLimitFilterMode } from './types';

export const portalSettingsSlice = createSlice({
  name: 'portalSettings',
  initialState,
  reducers: {
    setParametersExceededFilterMode: (state, action: PayloadAction<VehicleParametersExceedeFilterMode>) => {
      state.parametersExceededFilterMode = action.payload;
    },
    setWeightLimitFilterMode: (state, action: PayloadAction<VehicleWeightLimitFilterMode>) => {
      state.weightLimitFilterMode = action.payload;
    },
  },
});

export const { 
  setParametersExceededFilterMode,
  setWeightLimitFilterMode,
} = portalSettingsSlice.actions;
export default portalSettingsSlice.reducer;
